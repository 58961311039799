import {Routes, Route} from "react-router-dom";
import CustomNavbar from "./components/CustomNavbar";
import {HomePage} from "./components/HomePage";
import {Impressum} from "./components/Impressum";
import Layout from "./Layout";
import {Datenschutz} from "./components/Datenschutz";
import {Biodanzaschule} from "./components/Biodanzaschule";
import {UeberMich} from "./components/UeberMich";
import Kontakt from "./components/Kontakt";
import {Ausbildungskalender} from "./components/Ausbildungskalender"
import {EinBeruf} from "./components/EinBeruf";
import {Ausbildung} from "./components/Ausbildung";
import {WasIstBiodanza} from "./components/WasIstBiodanza";
import {Links} from "./components/Links";
import {Mitmachen} from "./components/Mitmachen"
import {DasBiozentrischeSystem} from "./components/DasBiozentrischeSystem"
import {Events} from "./components/Events"




function AppRouter() {
    return (
        < >
            <CustomNavbar/>
            <Layout>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/biodanza" element={<WasIstBiodanza/>}/>
                <Route path="/das-biozentrische-system" element={<DasBiozentrischeSystem/>}/>

                <Route path="/mitmachen" element={<Mitmachen/>}/>

                <Route path="/ein-Beruf-mit-Zukunft" element={<EinBeruf/>}/>
                <Route path="/biodanzaschuleFreibung" element={<Biodanzaschule/>}/>
                <Route path="/ausbildung" element={<Ausbildung/>}/>
                <Route path="/ausbildungskalender" element={<Ausbildungskalender/>}/>
                <Route path="/schulleitungBeateHoffman" element={<UeberMich/>}/>
                <Route path="/events" element={<Events/>}/>
                <Route path="/links" element={<Links/>}/>
                <Route path="/kontakt" element={<Kontakt/>}/>

                <Route path="/impressum" element={<Impressum/>}/>
                <Route path="/datenschutzerklaerung" element={<Datenschutz/>}/>

            </Routes>
            </Layout>

        </>
    );
}

export default AppRouter;
