import { BackButton } from "../UI/Buttons";

export const Datenschutz = () => {
    return (
        <>
            <BackButton path={-1} />

            <div className="datenschutz center">
                <div className="impressum-content ">

                    <h1>Datenschutzerklärung</h1>

                    <div>
                        <h2>1. Einleitung</h2>
                        <p>   Mit den folgenden Informationen möchten wir Ihnen als "betroffener Person" einen Überblick über die Verarbeitung Ihrer personenbezogenen Daten durch uns und Ihre Rechte aus den Datenschutzgesetzen geben. Eine Nutzung unserer Internetseiten ist grundsätzlich ohne Eingabe personenbezogener Daten möglich. Sofern Sie besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchten, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung von Ihnen ein. <br /><br />
                            Die Verarbeitung von personenbezogenen Daten, beispielsweise Ihres Namens, der Anschrift oder der E-Mail-Adresse, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung (DS-GVO) und in Übereinstimmung mit den geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchten wir Sie über Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren.<br /><br />
                            Wir haben als für die Verarbeitung Verantwortliche zahlreiche technische und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es Ihnen frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch oder per Post, an uns zu übermitteln.</p>

                        <h2>2. Verantwortlicher   </h2>
                        <p>Verantwortlicher im Sinne der DS-GVO ist die: <br />
                            Beate Hoffmann<br />
                            Königsgarten 9<br />
                            67146 Deidesheim<br />
                            Telefon: +49 177 43 47 677<br />
                            E-Mail: info@biodanzaschule-freiburg.de</p>

                        <h2>3. Datenschutzbeauftragter   </h2>
                        <p>Wir weisen darauf hin, dass kein Datenschutzbeauftragter benannt werden muss. Der Ansprechpartner zum Thema Datenschutz: info@biodanzaschule-freiburg.de</p>

                        <h2>  4. Begriffsbestimmungen </h2>
                        <p> Die Datenschutzerklärung beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.<br /><br />
                            <b>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:
                            </b></p>
                        <h3> 1. Personenbezogene Daten </h3>
                        <p> Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.
                            <h3> 2. Betroffene Person </h3>
                            Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen (unser Unternehmen) verarbeitet werden.
                            <h3>  3. Verarbeitung </h3>
                            Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
                            <h3> 4. Einschränkung der Verarbeitung </h3>
                            Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.
                            <h3> 5. Profiling </h3>
                            Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
                            <h3> 6. Pseudonymisierung </h3>
                            Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
                            <h3> 7. Auftragsverarbeiter </h3>
                            Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
                            <h3> 8. Empfänger </h3>
                            Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
                            <h3> 9. Dritter </h3>
                            Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
                            <h3>10. Einwilligung  </h3>
                            Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass Sie mit der Verarbeitung der Sie betreffenden personenbezogenen Daten einverstanden ist.</p>

                        <h2>  5. Rechtsgrundlage der Verarbeitung </h2>
                        <p> Art. 6 Abs. 1 lit. a) DS-GVO (i.V.m. § 25 Abs. 1 TTDSG) dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen.<br /> <br />
                            Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 Abs. 1 lit. b) DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren Produkten oder Leistungen.<br /> <br />
                            Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 Abs. 1 lit. c) DS-GVO.<br /> <br />
                            In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 Abs. 1 lit. d) DS-GVO beruhen.<br /> <br />
                            Letztlich könnten Verarbeitungsvorgänge auf Art. 6 Abs. 1 lit. f) DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein könnte, wenn Sie ein Kunde unseres Unternehmens sind (Erwägungsgrund 47 Satz 2 DS-GVO).</p>

                        <h2>  6. Übermittlung von Daten an Dritte sowie in Drittstaaten </h2>
                        <p>   Eine Übermittlung Ihrer personenbezogenen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.<br /> <br />
                            <b>Wir geben Ihre personenbezogenen Daten nur an Dritte weiter, wenn:</b> <br /> <br />
                            1. Sie uns Ihre nach Art. 6 Abs. 1 lit. a) DS-GVO ausdrückliche Einwilligung dazu erteilt haben,<br /> <br />
                            2. die Weitergabe nach Art. 6 Abs. 1 lit. f) DS-GVO zur Wahrung unserer berechtigten Interessen zulässig ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,<br /> <br />
                            3. für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 lit. c) DS-GVO eine gesetzliche Verpflichtung besteht, sowie<br /> <br />
                            4. dies gesetzlich zulässig und nach Art. 6 Abs. 1 lit. b) DS-GVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.<br /> <br />

                            Im Rahmen der in dieser Datenschutzerklärung beschriebenen Verarbeitungsvorgänge können personenbezogene Daten in die USA übermittelt werden. Unternehmen in den USA verfügen nur dann über ein angemessenes Datenschutzniveau, sofern sie sich unter dem EU-US Data Privacy Framework zertifiziert haben und somit der Angemessenheitsbeschluss der EU-Kommission gem. Art. 45 DS-GVO greift. Wir haben dies bei den betroffenen Dienstleistern in der Datenschutzerklärung explizit genannt. Um Ihre Daten in allen anderen Fällen zu schützen, haben wir Vereinbarungen zur Auftragsverarbeitung auf Grundlage der Standardvertragsklauseln der Europäischen Kommission abgeschlossen. Sofern die Standardvertragsklauseln nicht ausreichen, um ein adäquates Sicherheitsniveau herzustellen kann Ihre Einwilligung gemäß Art. 49 Abs. 1 lit. a) DS-GVO als Rechtsgrundlage für die Übermittlung in Drittländer dienen. Dies gilt mitunter nicht bei einer Datenübermittlung in Drittländer, für welche die Europäische Kommission einen Angemessenheitsbeschluss gemäß Art. 45 DS-GVO ausgestellt hat.</p>

                        <h2> 7. Technik  </h2>
                        <h3>  7.1 SSL/TLS-Verschlüsselung </h3>
                        <p>  Diese Seite nutzt zur Gewährleistung der Sicherheit der Datenverarbeitung und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen, Login-Daten oder Kontaktanfragen, die Sie an uns als Betreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass in der Adresszeile des Browsers statt einem "http://" ein "https://" steht und an dem Schloss-Symbol in Ihrer Browserzeile. Wir setzen diese Technologie ein, um Ihre übermittelten Daten zu schützen.</p>

                        <h3>  7.2 jsDelivr </h3>
                        <p> Auf unserer Internetseite sind Komponenten von jsDelivr, betrieben vom Anbieter Prospect One, Królewska 65A/1, PL-30-081 Kraków, Polen, integriert. <br />
                            Wir setzen auf unserer Website den Open-Source Dienst jsDelivr ein, um Inhalte unserer Website möglichst rasch und technisch einwandfrei an verschiedene Endgeräte von Nutzern ausliefern zu können. <br />
                            jsDelivr ist ein Content-Delivery-Network (CDN), dass die Inhalte auf unserer Website über verschiedene Server verteilt, um eine optimale weltweite Erreichbarkeit sicherzustellen. Ein CDN nutzt in der Regel Server, die geografisch nahe beim jeweiligen Website-Nutzer liegen. Daher ist anzunehmen, dass Nutzer innerhalb der EU über Server innerhalb der EU mit Inhalten versorgt werden. Zur Bereitstellung der Inhalte erfasst jsDelivr Nutzerdaten wie die IP-Adresse. <br /> <br />
                            jsDelivr verwendet laut Angaben des Anbieters keine Cookies oder ähnliche Tracking-Technologien, sondern ist nur aus den oben genannten technischen Gründen notwendig. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a) DS-GVO. <br /> <br />
                            Die Datenschutzbestimmungen von jsDelivr können Sie einsehen unter: https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net.</p>

                        <h3>7.3 Externes Hosting  </h3>
                        <p> Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln. Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b) DS-GVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f) DS-GVO). <br /> <br />
                            Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a) DS-GVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar. Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
                            Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen. Wir setzen folgende(n) Hoster ein: <br /> <br />
                            Netlify, Inc. <br />
                            2325 3rd Street, Suite 215 <br />
                            San Francisco, CA 94107 <br />
                            USA <br />  <br />

                            Netlify, Inc. ist als US-Unternehmen unter dem EU-US Data Privacy Framework zertifiziert. Es liegt hiermit ein Angemessenheitsbeschluss gem. Art. 45 DS-GVO vor, sodass eine Übermittlung personenbezogener Daten erfolgen darf. Weiterführende Informationen entnehmen Sie der Datenschutzerklärung von Netlify unter: https://www.netlify.com/privacy/</p>


                        <h2>  8. Cookies </h2>
                        <h3>8.1 Allgemeines zu Cookies  </h3>
                        <p>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt. Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen). Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezwecken verwendet werden. <br /> <br />
                            Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f) DS-GVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a) DS-GVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar. <br /> <br />
                            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</p>

                        <h2> 9. Inhalte unserer Internetseite  </h2>
                        <h3>  9.1 Kontaktaufnahme </h3>
                        <p>  Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum Zwecke der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f) DS-GVO. Zielt Ihre Kontaktaufnahme auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b) DS-GVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>

                        <h2> 10. Newsletterversand  </h2>
                        <h3>  10.1 Brevo (vorher Sendinblue) </h3>
                        <p> Diese Webseite nutzt Brevo für den Versand von Newslettern. Anbieter ist die Sendinblue GmbH, Köpenicker Str. 126, 10179 Berlin, Deutschland. Brevo ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert werden kann. Die von Ihnen zum Zwecke des Newsletterbezugs eingegeben Daten werden auf den Servern von Sendinblue in Deutschland gespeichert. <br /> <br />
                            Wenn Sie keine Analyse durch Brevo wünschen, ist die Abbestellung des Newsletters erforderlich. Hierfür stellen wir in jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Des Weiteren können Sie den Newsletter auch direkt auf der Webseite abbestellen. Die von Ihnen erteilte Einwilligung können Sie jederzeit widerrufen. Die Verarbeitung können Sie auch jederzeit dadurch verhindern, dass Sie den Newsletter abbestellen. Auch können Sie die Speicherung von Cookies durch entsprechende Einstellung Ihres Webbrowsers verhindern. Auch durch die Deaktivierung von Java-Script in Ihrem Webbrowser oder die Installation eines Java-Script-Blockers (z.B. https://noscript.net oder https://www.ghostery.com) können Sie die Speicherung und Übermittlung personenbezogenen Daten verhindern. Wir weisen darauf hin, dass durch diese Maßnahmen möglicherweise nicht mehr alle Funktionen unseres Internetangebotes zur Verfügung stehen. <br /> <br />
                            Mit Hilfe von Brevo ist es uns möglich, unsere Newsletter-Kampagnen zu analysieren. So können wir z.B. sehen, ob eine Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt worden sind. Auf diese Weise können wir u.a. feststellen, welche Links besonders häufig angeklickt wurden. <br /> <br />
                            Außerdem können wir erkennen, ob nach dem Öffnen/ Anklicken bestimmte vorher definierte Aktionen durchgeführt wurden (Conversion-Rate). Wir können so z.B. erkennen, ob Sie nach dem Anklicken des Newsletters einen Kauf getätigt haben. Brevo ermöglicht es uns auch, die Newsletter-Empfänger in verschiedene Kategorien zu unterteilen (sog. "Clustern"). Dabei lassen sich die Newsletterempfänger z.B. nach Alter, Geschlecht oder Wohnort unterteilen. Auf diese Weise lassen sich die Newsletter besser an die jeweiligen Zielgruppen anpassen. <br /> <br />
                            Ausführliche Informationen zu den Funktionen von Brevo entnehmen Sie folgendem Link: https://www.brevo.com/de/features/. <br /> <br />
                            Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO. Sie können diese Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von unseren Servern als auch von den Servern von Brevo gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt. <br /> <br />
                            Die Datenschutzbestimmungen von Brevo können Sie einsehen unter: https://www.brevo.com/de/datenschutz-uebersicht/.</p>

                        <h2>11. Plugins und andere Dienste   </h2>
                        <h3>  11.1 Font Awesome </h3>
                        <p> Unsere Webseite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Fonticons Inc, 307 S Main St Ste 202 Bentonville, AR, USA bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in Ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen. <br /> <br />
                            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Fonticons, Inc. aufnehmen. Hierdurch erlangt Fonticons, Inc. Kenntnis darüber, dass über Ihre IP-Adresse unsere Webseite aufgerufen wurde. Die Nutzung von Font Awesome erfolgt ausschließlich bei Erteilung einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO. <br /> <br />
                            Wenn Ihr Browser Font Awesome nicht unterstützt, wird eine Standardschrift von Ihrem Endgerät genutzt. Nähere Informationen zu den Datenschutzbestimmungen von Fonticons Inc. finden Sie unter: https://fontawesome.com/privacy.</p>

                        {/* <h3>  11.2 Google WebFonts </h3>
                        <p> Unsere Webseite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts. Die Google WebFonts werden bereitgestellt von der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Google Ireland Limited ist Teil der Google Firmengruppe mit Hauptsitz in 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.<br /> <br />
                            Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO.<br /> <br />
                            Das Mutterunternehmen Google LLC ist als US-Unternehmen unter dem EU-US Data Privacy Framework zertifiziert. Es liegt hiermit ein Angemessenheitsbeschluss gem. Art. 45 DS-GVO vor, sodass eine Übermittlung personenbezogener Daten auch ohne weitere Garantien oder zusätzliche Maßnahmen erfolgen darf. Weiterführende Informationen zu den Google WebFonts sowie die Datenschutzerklärung von Google können Sie einsehen unter: https://developers.google.com/fonts/faq; https://www.google.com/policies/privacy/.</p> */}

                        <h3> 11.2 Google reCAPTCHA </h3>
                        <p> Auf dieser Webseite verwenden wir die reCAPTCHA Funktion. Betreibergesellschaft von Google reCAPTCHA ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Google Ireland Limited ist Teil der Google Firmengruppe mit Hauptsitz in 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Die reCAPTCHA Funktion dient vor allem zur Unterscheidung, ob eine Eingabe durch eine natürliche Person erfolgt oder missbräuchlich durch maschinelle und automatisierte Verarbeitung erfolgt. Der Dienst umfasst auch den Versand der IP-Adresse und ggf. weiterer von Google für den Dienst reCAPTCHA benötigter Daten an Google.<br /> <br />
                            Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO.<br /> <br />
                            Das Mutterunternehmen Google LLC ist als US-Unternehmen unter dem EU-US Data Privacy Framework zertifiziert. Es liegt hiermit ein Angemessenheitsbeschluss gem. Art. 45 DS-GVO vor, sodass eine Übermittlung personenbezogener Daten auch ohne weitere Garantien oder zusätzliche Maßnahmen erfolgen darf.<br /> <br />
                            Weiterführende Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google können Sie einsehen unter: https://www.google.com/intl/de/policies/privacy/.</p>

                        <h3>11.3 Kommunikation via Messenger-Dienste  </h3>
                        <p> Wir bieten Besuchern unserer Website die Möglichkeit, mit uns über den Nachrichtendienst WhatsApp der Meta Platforms Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, in Kontakt zu treten. Hierfür verwenden wir die sog. „Business-Version“ von WhatsApp. Wenn Sie uns per WhatsApp ansprechen, erklären Sie Ihr Einverständnis, dass wir die Kommunikation auf diesem Weg mit Ihnen führen (Art. 6 Abs. 1 lit. a) DS-GVO). Über die Beantwortung Ihres Anliegens hinaus, werden wir Sie nicht aktiv per WhatsApp kontaktieren.<br /> <br />
                            Sollten Sie über den Messenger-Dienst WhatsApp Kontakt mit uns aufnehmen wollen, übermitteln Sie uns damit automatisch ihre Mobilnummer. Diese wird auf unseren gesicherten mobilen Endgeräten gespeichert. Wir verwenden die Mobilnummer nur und ausschließlich für die WhatsApp-Kommunikation. Dabei behandeln wir Ihre Daten und die Kommunikationsdaten nach geltendem Datenschutzrecht. Die zugriffsberechtigten Personen sind zusätzlich schriftlich auf das Datengeheimnis verpflichtet und werden regel-mäßig geschult.<br /> <br />
                            Wir dürfen Sie darauf hinweisen, dass für die Korrespondenz die Nutzungs- und Datenschutzbedingungen von WhatsApp Anwendung finden, auf die wir keinen Einfluss haben. Die Bedingungen von WhatsApp, können Sie unter  https://www.whatsapp.com/legal/#privacy-policy  einsehen. Bitte beachten Sie, dass WhatsApp Business Zugriff auf das Adressbuch des von uns hierfür verwendeten mobilen Endgeräts erhält und im Adressbuch gespeicherte Telefonnummern automatisch an einen Server des Mutterkonzerns Meta Platforms Inc. in den USA überträgt. Das Mutterunternehmen Meta Platforms, Inc. ist als US-Unternehmen unter dem EU-US Data Privacy Framework zertifiziert. Es liegt hiermit ein Angemessenheitsbeschluss gem. Art. 45 DS-GVO vor, sodass eine Übermittlung personenbezogener Daten auch ohne weitere Garantien oder zusätzliche Maßnahmen erfolgen darf.<br /> <br />
                            Für den Betrieb unseres WhatsApp-Business-Kontos verwenden wir ein mobiles Endgerät, in dessen Adressbuch ausschließlich die WhatsApp-Kontaktdaten solcher Nutzer gespeichert werden, die mit uns per WhatsApp auch in Kontakt getreten sind. Hierdurch wird sichergestellt, dass jede Person, deren WhatsApp- Kontaktdaten in unserem Adressbuch gespeichert sind, bereits bei erstmaliger Nutzung der App auf seinem Gerät durch Akzeptanz der WhatsApp-Nutzungsbedingungen in die Übermittlung seiner WhatsApp-Telefonnummer aus den Adressbüchern seiner Chat-Kontakte gemäß Art. 6 Abs. 1 lit. a) DS-GVO eingewilligt hat. Eine Übermittlung von Daten solcher Nutzer, die WhatsApp nicht verwenden und/oder uns nicht über WhatsApp kontaktiert haben, wird insofern ausgeschlossen.<br /> <br />
                            Wir empfehlen in Ihrem eigenen Interesse: Verzichten Sie in Ihrer Korrespondenz mit uns auf die Übermittlung persönlicher Daten per WhatsApp. Hierzu gehören beispielweise Konto- und Vertragsdaten. Sollte sich eine Korrespondenz in diese Richtung entwickeln, bitten wir um Verständnis, dass wir Sie auf einen anderen Kommunikationsweg (Telefon, E-Mail, Brief) verweisen werden. Innerhalb unseres Unternehmens erhalten diejenigen Stellen Zugriff auf Ihre Daten, die diese zur Erfüllung der oben genannten Zwecke brauchen. Das gilt auch für von uns eingesetzte Dienstleister und Erfüllungsgehilfen. Es werden keine Userprofile erstellt, die Daten werden nicht an Dritte weitergegeben, soweit dies nicht durch die allgemeinen Geschäftsbedingungen durch WhatsApp bereits als Nutzungsbedingung festgelegt ist. Bitte beachten Sie, dass die Vertraulichkeit bei WhatsApp nicht gewährleistet ist. Wenn Sie WhatsApp auf ihrem Mobilgerät installiert haben, stimmen Sie den Allgemeinen Geschäftsbedingungen von WhatsApp zu, auf die wir keinen Einfluss haben. Diese beinhalten unter anderem, dass Sie der Meta Platforms Inc. vielfältige Zugriffe gewähren. Darüber hinaus werden Daten auf Servern von Meta Platforms Inc. gespeichert, die nicht dem europäischen Datenschutzrecht unterliegen. Die Datenschutzbedingungen von WhatsApp finden Sie unter   https://www.whatsapp.com/legal/#privacy-policy.<br /> <br />
                            Wir speichern Ihre personenbezogenen Daten für die o.g. Zwecke. Ihre Daten werden erstmals ab dem Zeitpunkt der Erhebung verarbeitet. Sofern die Daten einen Bezug zu einem Vertrag haben, verarbeiten wir die Daten innerhalb der gesetzlichen Aufbewahrungsfristen (insbesondere nach HGB und AO). Der WhatsApp-Chat wird spätestens nach einer Woche nach der Durchführung der Kommunikation gelöscht.</p>

                        <h2>  12. Aktivitäten in sozialen Netzwerken </h2>
                        <p>Damit wir mit Ihnen auch in sozialen Netzwerken kommunizieren und Sie über unsere Leistungen informieren können, sind wir dort mit eigenen Seiten vertreten. Wenn Sie eine unserer Social-Media-Seiten besuchen, sind wir hinsichtlich der dadurch ausgelösten Verarbeitungsvorgänge, im Sinne des Art. 26 DS-GVO, mit dem Anbieter der jeweiligen Social-Media-Plattform gemeinsam für die Verarbeitung verantwortlich. Wir sind dabei nicht der originäre Anbieter dieser Seiten, sondern nutzen diese lediglich im Rahmen der uns durch die jeweiligen Anbieter gebotenen Möglichkeiten.<br /> <br />
                            Daher weisen wir vorsorglich darauf hin, dass Ihre Daten möglicherweise auch außerhalb der Europäischen Union bzw. des Europäischen Wirtschaftsraumes verarbeitet werden. Eine Nutzung kann daher mit Datenschutzrisiken für Sie verbunden sein, da die Wahrung Ihrer Rechte z. B. auf Auskunft, Löschung, Widerspruch, etc. erschwert sein könnte und die Verarbeitung in den sozialen Netzwerken häufig direkt zu Werbezwecken oder zur Analyse des Nutzerverhaltens durch die Anbieter erfolgt, ohne dass dies durch uns beeinflusst werden kann. Werden durch den Anbieter Nutzungsprofile angelegt, werden dabei häufig Cookies eingesetzt bzw. das Nutzungsverhalten dem von Ihnen erstellten eigenen Mitgliedprofil der sozialen Netzwerke zugeordnet. Die beschriebenen Verarbeitungsvorgänge personenbezogener Daten erfolgen gemäß Art. 6 Abs. 1 lit. f) DS-GVO auf Basis unseres berechtigten Interesses und des berechtigten Interesses des jeweiligen Anbieters, um mit Ihnen in einer zeitgemäßen Art und Weise kommunizieren bzw. Sie über unsere Leistungen informieren zu können. Müssen Sie bei den jeweiligen Anbietern eine Einwilligung zur Datenverarbeitung als Nutzer abgeben, bezieht sich die Rechtsgrundlage auf Art. 6 Abs. 1 lit. a) DS-GVO:<br /> <br />
                            Da wir keine Zugriffe auf die Datenbestände der Anbieter haben, weisen wir darauf hin, dass Sie Ihre Rechte (z.B. auf Auskunft, Berichtigung, Löschung, etc.) am besten direkt bei dem jeweiligen Anbieter gelten machen. Weiterführende Informationen zur Verarbeitung Ihrer Daten in den sozialen Netzwerken haben wir nachfolgend bei dem jeweiligen von uns eingesetzten Anbieter sozialer Netzwerke aufgeführt:<br /> <br />
                            <p style={{ marginLeft: "40px" }}> •	Facebook
                                (Mit-) Verantwortlicher für die Datenverarbeitung in Europa:
                                Meta Platforms Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
                                Datenschutzerklärung (Datenrichtlinie): https://www.facebook.com/about/privacy
                            </p>

                        </p>

                        <h2>  13. Ihre Rechte als betroffene Person </h2>
                        <h3>  13.1 Recht auf Bestätigung </h3>
                        <p>Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden.</p>
                        <h3>  13.2 Recht auf Auskunft Art. 15 DS-GVO </h3>
                        <p>Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten sowie eine Kopie dieser Daten nach Maßgabe der gesetzlichen Bestimmungen zu erhalten.</p>
                        <h3> 13.3 Recht auf Berichtigung Art. 16 DS-GVO </h3>
                        <p> Sie haben das Recht, die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht Ihnen das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</p>
                        <h3>13.4 Löschung Art. 17 DS-GVO  </h3>
                        <p> Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der gesetzlich vorgesehenen Gründe zutrifft und soweit die Verarbeitung bzw. Speicherung nicht erforderlich ist.</p>
                        <h3> 13.5 Einschränkung der Verarbeitung Art. 18 DS-GVO </h3>
                        <p>Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der gesetzlichen Voraussetzungen gegeben ist.</p>
                        <h3> 13.6 Datenübertragbarkeit Art. 20 DS-GVO </h3>
                        <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, welche uns durch Sie bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO oder Art. 9 Abs. 2 lit. a) DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b) DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche uns übertragen wurde.<br /> <br />
                            Ferner haben Sie bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.</p>

                        <h3>  13.7 Widerspruch Art. 21 DS-GVO </h3>
                        <p>SIE HABEN DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, JEDERZEIT GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN, DIE AUFGRUND VON ART. 6 ABS. 1 LIT. E) (DATENVERARBEITUNG IM ÖFFENTLICHEN INTERESSE) ODER F (DATENVERARBEITUNG AUF GRUNDLAGE EINER INTERESSENABWÄGUNG) DS-GVO ERFOLGT, WIDERSPRUCH EINZULEGEN. DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING IM SINNE VON ART. 4 NR. 4 DS-GVO.<br /> <br />
                            LEGEN SIE WIDERSPRUCH EIN, WERDEN WIR IHRE PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE BERECHTIGTE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN, ODER SOFERN DIE VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.<br /> <br />
                            IN EINZELFÄLLEN VERARBEITEN WIR PERSONENBEZOGENE DATEN, UM DIREKTWERBUNG ZU BETREIBEN. SIE KÖNNEN JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG DER PERSONENBEZOGENEN DATEN ZUM ZWECKE DERARTIGER WERBUNG EINLEGEN. DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WIDERSPRECHEN SIE GEGENÜBER UNS DER VERARBEITUNG FÜR ZWECKE DER DIREKTWERBUNG, SO WERDEN WIR DIE PERSONENBEZOGENEN DATEN NICHT MEHR FÜR DIESE ZWECKE VERARBEITEN.<br /> <br />
                            ZUDEM HABEN SIE DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE SIE BETREFFENDE VERARBEITUNG PERSONENBEZOGENER DATEN, DIE BEI UNS ZU WISSENSCHAFTLICHEN ODER HISTORISCHEN FORSCHUNGSZWECKEN ODER ZU STATISTISCHEN ZWECKEN GEMÄSS ART. 89 ABS. 1 DS-GVO ERFOLGEN, WIDERSPRUCH EINZULEGEN, ES SEI DENN, EINE SOLCHE VERARBEITUNG IST ZUR ERFÜLLUNG EINER IM ÖFFENTLICHEN INTERESSE LIEGENDEN AUFGABE ERFORDERLICH.<br /> <br />
                            IHNEN STEHT ES FREI, IM ZUSAMMENHANG MIT DER NUTZUNG VON DIENSTEN DER INFORMATIONSGESELLSCHAFT, UNGEACHTET DER RICHTLINIE 2002/58/EG, IHR WIDERSPRUCHSRECHT MITTELS AUTOMATISIERTER VERFAHREN AUSZUÜBEN, BEI DENEN TECHNISCHE SPEZIFIKATIONEN VERWENDET WERDEN.</p>
                        <h3>13.8 Widerruf einer datenschutzrechtlichen Einwilligung  </h3>
                        <p> Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit mit Wirkung für die Zukunft zu widerrufen.</p>
                        <h3> 13.9 Beschwerde bei einer Aufsichtsbehörde </h3>
                        <p> Sie haben das Recht, sich bei einer für Datenschutz zuständigen Aufsichtsbehörde über unsere Verarbeitung personenbezogener Daten zu beschweren.</p>

                        <h2>  14. Routinemäßige Speicherung, Löschung und Sperrung von personenbezogenen Daten </h2>
                        <p> Wir verarbeiten und speichern Ihre personenbezogenen Daten nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch die Rechtsvorschriften, welchen unser Unternehmen unterliegt, vorgesehen wurde.<br /> <br />
                            Entfällt der Speicherungszweck oder läuft eine vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.</p>


                        <h2>  15. Dauer der Speicherung von personenbezogenen Daten </h2>
                        <p>Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.</p>

                        <h2>  16. Aktualität und Änderung der Datenschutzerklärung </h2>
                        <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand: April 2024.<br /> <br />
                            Durch die Weiterentwicklung unserer Internetseiten und Angebote oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Webseite unter https://biodanzaschule-freiburg.de/datenschutzerklaerung von Ihnen abgerufen und ausgedruckt werden.
                        </p>


                    </div>
                </div>
            </div>
        </>
    )
}