import React from 'react';
import ReactDOM from "react-dom";
import './css/index.scss';
import { BrowserRouter } from "react-router-dom";
import AppRouter from './AppRouter';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>
    </BrowserRouter>,
);


