import "../css/article.scss"
import {BackButton} from "../UI/Buttons";

export const DasBiozentrischeSystem = () => {
    return (
        <>
            <BackButton path={-1} />

            <div className="article-container-new">
                <div className="article center">


                    <h2>
                        EIN NEUES PARADIGMA FÜR DAS DRITTE JAHRTAUSEND
                    </h2>

                    <h3>DAS BIOZENTRISCHE PRINZIP:
                      <br/>  (AUSZUG AUS DEN SCHRIFTEN VON ROLANDO TORO)
                    </h3>

                    <p>

                        Der Beginn eines neuen Jahrtausends in der Menschheitsgeschichte weckt im kollektiven Bewusstsein die Hoffnung auf Erlösung und die Angst vor der Apokalypse. In Wirklichkeit haben beide Perspektiven einen magischen Charakter und stehen nicht in direktem Zusammenhang mit unserer inneren Verantwortung für den Prozess der Veränderung.
                        <br/><br/>
                        Die Zukunft hängt ausschließlich davon ab, ob wir eine Transformation unseres Bewusstseins vollziehen, indem wir in uns die reine Energie der Verbindung mit dem Leben entdecken. Das biozentrische Prinzip schlägt die Sakralität des Lebens vor. Alle menschlichen Ziele müssen sich in den Dienst der Erhaltung des Lebens stellen und das Leben fördern.
                        <br/><br/>
                        Das 20. Jahrhundert war, ungeachtet des wunderbaren intellektuellen Fortschritts, eine Zeit der Niedertracht. Nie zuvor gab es so viel Gewalt und Bosheit als in dieser Zeit. Die für unsere Entwicklung notwendige Zärtlichkeit hat uns gefehlt. Die Welt ist geistig verbrannt; wir haben wunderbares Wissen, aber es fehlt uns die Weisheit.
                        <br/><br/>
                        Das biozentrische Prinzip stellt die Verteidigung des Lebens, den Schutz der Unschuld und die Verehrung der Emotionalität in den Mittelpunkt unserer Aufmerksamkeit. Biodanza ist die Poetik der menschlichen Begegnung.  Die  Begegnung  von  verschiedenen  Populationen und Völkern. Die Begegnung von Ökologie und Entwicklung.
                        Die Begegnung der Wissenschaft mit dem Tanz, der intellektuellen Strenge mit der Komplexität des Lebens.
                        <br/><br/>
                        Wir suchen nach einem Modell für soziales Handeln, bei dem die Wirtschaft auf ethisches Bewusstsein und die Politik der Menschenrechte trifft.
                        Es ist wichtig, dass wir lernen, uns selbst zu betrachten und zu lernen anderen in die Augen zu schauen. Das Bildungssystem muss dringend neue Parameter in die intellektuelle Bildung einbeziehen, die die Emotionen und die reine Energie der Verbindung mit dem Leben anregen. Das biozentrische Prinzip muss auch auf die Geisteswissenschaften angewandt werden.
                        <br/><br/>
                        Biodanza repräsentiert das archaische Bedürfnis, auf das Wunder des Lebens zu reagieren und sich an der einfachen Tatsache des Lebens zu erfreuen.
                        <br/><br/>
                        Rolando Toro Araneda<br/>
                        Schöpfer von Biodanza


                    </p>

                </div></div>
                </> )}