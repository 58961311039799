import { BackButton } from "../UI/Buttons";

export const Impressum = () => {
    return (
        <>
            <BackButton path={-1} />

            <div className="impressum center">

                <div className="impressum-content" >


                    <header className=" ">
                        <h1 className="">Impressum</h1>
                    </header>

                    <div>

                        <h2>Angaben gemäß § 5 TMG</h2>

                        <p>Beate Hoffmann<br />
                            Königsgarten 9<br />
                            67146 Deidesheim</p>

                        <h2>Kontakt</h2>
                        <p>E-Mail: info@biodanzaschule-freiburg.de</p>
                        <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>


                    </div>
                </div>

            </div>
        </>
    )
}