import "../css/article.scss"
import { BackButton } from "../UI/Buttons";

export const EinBeruf = () => {
    return (
        <>
            <BackButton path={-1} />

            <div className="article-container-new">
                <div className="article center ">


                    <h2>
                        Biodanza - ein Beruf mit Zukunft
                    </h2>

                    <p>
                        Mache Dein Leben zur Berufung und beginne eine Ausbildung mit Zukunft! Dein Leben wird reichhaltiger, gesünder und fröhlicher. Du wirst andere Menschen in ihren Prozessen begleiten und Dich mit Deinen Talenten verwirklichen. Ein tiefes verinnerlichtes Verständnis von gesunden Lebensbedingungen und eine solide, theoretische Basis aufbauend auf aktuellen Erkenntnissen der Human- und Naturwissenschaften geben Dir den Rahmen, erfolgreich mit unterschiedlichsten Menschen zu arbeiten.
                        <br /><br />
                        Werde Teil einer Vision vieler Biodanza Lehrer:innen in einem weltweiten Netzwerk:
                    </p>


                    <h3 className="center">
                        „Das Leben in den Mittelpunkt stellen und schützen.“
                    </h3>

                    <p>
                        Biodanza unterstützt die Entfaltung der Persönlichkeit, wirkt selbstregulierend, stärkt das Selbstwertgefühl, die Lebensintensität und -qualität und die Solidarität innerhalb der menschlichen Gemeinschaft.
                        <br /><br />
                        Durch Bewegung, Tanz und Gesten auf Musik wird die Achtsamkeit und das Gefühl für sich selbst, für die Mitmenschen und für die Umwelt gestärkt und der Ausdruck und die Kommunikation gefördert. Das System Biodanza stützt sich auf das biozentrische Prinzip, bei dem das Leben der Menschen, Tier- und Pflanzenwelt und die Bedeutung der Ökosysteme in den Mittelpunkt gestellt werden. Die Biodanza-Tänzer erfühlen und erleben wie schützenswert und wertvoll das Leben und unsere Lebensgrundlagen sind und haben die Kraft, dies im Leben auszudrücken.
                    </p>



                </div>
            </div>
        </>
    )
}