// import Beate from "../imgs/Beate.jpg";
import Beate from "../imgs/profil-bild.png";
import "../css/global.scss"
import { BackButton } from "../UI/Buttons";
import "../css/article.scss"

export const UeberMich = () => {
    return (
        <>
            <BackButton path={-1} />

            <div className="article-container-new">
                <div className="article center ">

                    <h2>
                        Beate Hoffmann
                    </h2>

                    {/*<div className="d-flex flex-row">*/}

                    <div className="article-header d-flex center">
                        <img src={Beate} alt="Beate Hoffman" className="profil-pic" />
                    </div>

                    {/*    <div style={{paddingLeft: "40px", textAlign: "center"}}>*/}

                    {/*        <div className="title-with-image-container">*/}
                    {/*            <h2 className="title">*/}
                    {/*                <span className="image-text"> Beate Hoffmann</span>*/}
                    {/*            </h2>*/}
                    {/*        </div>*/}
                    <div className="about-me" >


                        <p style={{
                            textAlign: "center", fontWeight: "600", color: "#46a2b5", paddingTop: "0"
                        }}>

                            Jeder Mensch trägt ein ganz einzigartiges Geschenk in sich – sich selbst. Es ist so wunderbar
                            und heilsam sich selbst zu entdecken, zuzulassen und dem Leben anzuvertrauen. Entwicklung und
                            Lernen hören nie auf und beides geht leichter, wenn man im Fluß ist. Heute sehe ich mein
                            Potential darin, gemeinsam mit Anderen zu SEIN, durch Tanz, in der Begegnung und durch gemeinsam
                            inspiriertes L(i)eben lernen.

                            <p style={{ fontWeight: "400", color: "black" }}>
                                Kontakt: <br /> 0177 43 47 677
                            </p>

                        </p>

                        <div className="center about-me-title">
                            <h3 >
                                Direktorin der Biodanza Schule Freiburg
                                <br />  Diplom Sozialarbeiterin
                            </h3>

                            <p>
                                <ul style={{ paddingTop: "20px" }}  >
                                    <li>
                                        Mitglied der IBFed International und IBFed Deutschland
                                    </li>
                                    <li>
                                        Didakta Ausbildung 2018 - 2019
                                    </li>
                                    <li>
                                        Gründung und Mitarbeit in der Keimzelle zur Gründung
                                        einer Biodanza Lehrer Vereinigung 2020 - 2023
                                    </li>
                                    <li>
                                        Biodanza mit Geflüchteten Frauen und Kindern : Kurse in Meckenheim, Deutschland
                                    </li>
                                    <li>
                                        Biodanza mit Senioren: Kurse in Deidesheim, Deutschland
                                    </li>
                                    <li>
                                        Vier Elemente Lehre in Biodanza
                                    </li>
                                    <li>
                                        Vertiefungstagesworkshops, Frankreich und Deutschland
                                    </li>
                                    <li>
                                        Biodanza Wochenendseminare für Erwachsene
                                    </li>
                                    <li>
                                        Biodanza mit Kindern, Jugendlichen und Familien : Kurse in Revel und Albi, Frankreich
                                    </li>
                                    <li>
                                        Biodanza Wochengruppen seit 2012, Frankreich und Deutschland
                                    </li>
                                    <li>
                                        Lehrerausbildung bei Ricardo Toro in der Biodanza Schule Carcassonne Pays Cathare 2009 - 2014
                                    </li>
                                    <li>
                                        14 Jahre Selbstständigkeit in Süd Frankreich
                                    </li>
                                    <li>
                                        Freiberufliche Deutschtrainerin für Manager:innen in der beruflichen Weiterbildung, Luftfahrt
                                        Toulouse, Frankreich
                                    </li>
                                    <li>
                                        Ausbildung zur Tuina Praktikerin (TCM) an der Akademie Wang, Toulouse, Frankreich
                                    </li>
                                    <li>
                                        erster Biodanza Wochenkurs als Teilnehmerin 2002
                                    </li>
                                    <li>
                                        Aura Soma Farbberaterin, Frankreich
                                    </li>
                                    <li>
                                        Reitpädagogin, Ferienprogramm, Wochenkurse, Ausritte, Anfängerunterricht, Deutschland
                                    </li>
                                    <li>
                                        Diplom Sozialarbeiterin in Anstellung bei einem freien Träger der Jugendhilfe
                                    </li>
                                </ul></p>
                        </div>
                    </div>
                </div>


            </div >
        </>)
}