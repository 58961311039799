import Logo from "../imgs/Logo_Hari_eigen.jpg"
import {ReadMoreButton} from "../UI/Buttons";
import "../css/article.scss"

export const ArticleSchule = () => {
    return (
        <div className="d-flex center">

            <div  className="article-container d-flex center">
                <div className="article-header d-flex center">
                    <img src={Logo} alt="Logo" style={{height: "200px"}}/>
                </div>

                <div className="title-with-image-container">
                    <h2 className="title">
                        <span className="image-text">Biodanza Schule Freiburg</span>
                    </h2>
                </div>

                <div className="article-content center">
                    <p>
                        Die Biodanza Schule Freiburg lebt eine Willkommenskultur und ist Mitglied der Internationalen
                            Biodanza Federation.
                            <br/> <br/>
                            Wir arbeiten nach den internationalen Ausbildungsrichtlinien der IBFed und machen uns zum Ziel,
                            Schüler:innen individuel in ihren Entwicklungsprozessen bis hin zur Ausübung des Berufes als
                            Biodanzalehrer:in zu begleiten. Mit der Ausbildung erwirbst Du eine berufliche, international
                            anerkannte Qualifikation.
                    </p>

                    <ReadMoreButton link={"/biodanzaschuleFreibung"}/>

                </div>



            </div>
        </div>
    );
};


