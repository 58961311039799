import "../css/article.scss"
import { BackButton } from "../UI/Buttons";
import pdfUrl from "../pdf/Flyer_Biodanzaschule_Freiburg.pdf"
import BilderGallery from './BilderGallery'

export const Ausbildung = () => {


    const styles = {
        container: {
            marginTop: '20px',
        },
        link: {
            textDecoration: 'none',
            color: '#fff',
            backgroundColor: '#5db7c9',
            padding: '12px 24px',
            borderRadius: '8px',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease',
            display: 'inline-block',
        },
        linkHover: {
            backgroundColor: '#307a88',
        },
    };

    const handleHover = (e) => {
        e.target.style.backgroundColor = '#307a88';
    };

    const handleLeave = (e) => {
        e.target.style.backgroundColor = '#5db7c9';
    };


    return (
        <>
            <BackButton path={-1} />


            <div className="article-container-new">
                <div className="article center">

                    <h2>
                        Ausbildung
                    </h2>

                    <p>
                        Die Biodanza-Schule Freiburg bietet eine theoretische, methodische und erlebnisorientierte Ausbildung, die durch langjährige Forschung perfektioniert wurde und qualifiziert zum/zur Biodanza Lehrer:in mit einem international durch die IBFed anerkannten Diplom. Gemäß den methodischen Grundlagen von Professor Rolando Toro und dem Einheitlichen Ausbildungsprogramm besteht das Ziel der Schule darin, eine qualitativ hochwertige Ausbildung anzubieten, die für die Ausübung des Berufs des Biodanza-Lehrers geeignet ist.
                    </p>

                    <h3>
                        Gemäß den international einheitlichen Ausbildungsrichtlinien der IBFed  beinhaltet die Ausbildung folgende Kriterien:
                    </h3>

                    <p>
                        <ul>
                            <li>
                                Teilnahme an 30 aufeinanderfolgenden Modulen
                            </li>
                            <li>
                                Anfertigung einer schriftliche Zusammenfassung zu jedem Modul
                            </li>
                            <li>
                                Teilnahme an Vorträgen zur Theorie von Biodanza
                            </li>
                            <li>
                                Eigenstudium zur Vor- und Nachbereitung der Module
                            </li>
                            <li>
                                Teilnahme an Studiengruppen
                            </li>
                            <li>
                                Regelmäßige Teilnahme an einer fortlaufenden Wochengruppe oder an Workshops
                            </li>
                            <li>
                                Leiten einer eigenen Gruppe unter Supervision im dritten oder vierten Ausbildungsjahr
                            </li>
                            <li>
                                Anfertigung einer Abschlussarbeit über ein theoretisches oder praktisches Thema und Vorstellen der Arbeit vor einer Kommission
                            </li>
                        </ul>
                    </p>

                    <h3>
                        Kriterien für die Aufnahme in die Biodanzaschule Freiburg
                    </h3>

                    <p>
                        <ul>
                            <li>
                                regelmäßige Teilnahme an einer wöchentlichen Biodanza-Gruppe oder Biodanza Erfahrung*
                            </li>
                            <li>
                                Motivationsschreiben
                            </li>
                            <li>
                                Empfehlungsschreiben des/der Biodanza Lehrer:in
                            </li>
                            <li>
                                Schulbildung: Abgeschlossene Sekundarschule
                            </li>
                            <li>
                                gute physische und psychische Gesundheit
                            </li>
                            <li>
                                Unterzeichnung des Vertrags mit der Biodanza Schule Freiburg (anerkennung des Ausbildungskonzeptes und der Hausordnung)
                                <br />   * Ausnahmen müssen der mit der Direktorin besprochen werden.
                            </li>
                            <li>
                                Mit jedem zukünftigen Schüler wird ein Motivationsgespräch geführt. In diesem Gespräch werden auch die Arbeitsweise der Schule und die Rolle der einzelnen Beteiligten erklärt.
                            </li>

                        </ul>

                    </p>
                    <h3>
                        Ausbildungskosten:

                    </h3>

                    <p>
                        Die Gesamtkosten der Ausbildung 2024 bis 2027 betragen 7700,00 € und setzen sich wie
                        folgt zusammen: <br /><br />
                        <b>190,00 € </b> pädagogische Gebühr pro Modul über 3 Jahre <br />
                        <b>65,00 € </b> Unterkunft (Übernachtung von Freitag auf Sonntag, Nutzung Seminarhaus) <br />
                        <b>50,00 €</b> einmalige Einschreibegebühr <br /><br />
                        <b>Dies ist ein Gesamtbetrag von 255,00 € pro Modul/Ausbildungsmonat.<br /><br />
                            Skonto: </b> Bei Einmalzahlung, vollständig zahlbar bis zum ersten Ausbildungsmodul, <br />
                        reduzieren sich die Kosten um 500,00 € auf 7.200,00 €. <br /> <br />
                        Zusätzlich sind zu berücksichtigen: <br />
                        - die Kosten für mind. acht Supervisionssitzungen (derzeit 180,- € zzgl. Fahrtkosten) <br />
                        - Kosten für die Verpflegung während der Ausbildungsmodule. <br />

                    </p>
                    <h3>
                        Gasthörer
                    </h3>

                    <p>
                        Personen, die ihr Wissen und ihre Erfahrung in Biodanza vertiefen möchten, können an den Modulen der Biodanza Schule Freiburg teilnehmen, jedoch nicht an den Methodikmodulen. Der Status eines Gasthörers wird nur für die ersten 20 Module akzeptiert. Die Person muss dann entscheiden, ob sie in die Gruppe der auszubildenden Schüler:innen aufgenommen werden möchte. Wenn ein Gasthörer zu einem späteren Zeitpunkt an der offiziellen Ausbildung zum/zur Biodanza-Lehrer:in teilnehmen möchte, muss er/sie alle fehlenden Zusammenfassungen vorlegen und ein Gespräch mit der Direktorin der Schule suchen, um festzustellen, ob das Niveau dem der Schüler:innen in der Ausbildung entspricht.
                    </p>
                    <h3>Kündigung und Rücktrittsbedingungen</h3>

                    <p> Wenn ein/e Teilnehmer:in sich aus wichtigem Grund zum Austritt aus der
                        Ausbildungsgruppe entschließt, ist dies der Schulleitung rechtzeitig unter Einhaltung der
                        Kündigungsfrist schriftlich mitzuteilen. Die Kündigung des Ausbildungsvertrages ist
                        halbjährig jeweils zum Ende des Halbjahres möglich (Juni und Dezember). Die Kündigung
                        muss schriftlich bis 6 Wochen vor Ende des Halbjahres bei der Leitung der Schule
                        eingegangen sein. Die monatlichen Raten sind bis zum Ende des jeweiligen Halbjahres
                        weiterhin zu entrichten. Die Nichtteilnahme des/der Teilnehmer:in an
                        Ausbildungsveranstaltungen entbindet nicht von der Zahlungspflicht.</p>



                    <p style={{ fontWeight: "650" }}>
                        Wenn Sie Interesse an weiteren Informationen haben, setzen Sie sich bitte mit uns in Verbindung. Wir
                        senden Ihnen gerne unsere Unterlagen zu oder beraten Sie in einem persönlichen Gespräch.<br /><br />
                        Beate Hoffmann: <br />

                        <a className="link" href="mailto:info@biodanzaschule-freiburg.de">
                            info@biodanzaschule-freiburg.de </a>

                        <div style={styles.container}>
                            <div style={{ textDecoration: 'underline' }}>
                                <a
                                    href={pdfUrl}
                                    download="Flyer_Biodanzaschule_Freiburg.pdf"
                                    style={styles.link}
                                    onMouseEnter={handleHover}
                                    onMouseLeave={handleLeave}
                                >
                                    <i className="fas fa-file-pdf" style={styles.icon}></i> Flyer herunterladen
                                </a>
                            </div>
                        </div>
                    </p>



                </div>
            </div>

            <BilderGallery />
        </>
    )
}