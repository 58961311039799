import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const Gallery = () => {
  const images = [
    {
      original: require('../imgs/beate1.jpg'),
      thumbnail: require('../imgs/beate1.jpg'),
    },
    {
      original: require('../imgs/beate2.jpg'),
      thumbnail: require('../imgs/beate2.jpg'),
    },
    {
      original: require('../imgs/beate3.jpg'),
      thumbnail: require('../imgs/beate3.jpg'),
    },
    {
      original: require('../imgs/Kreis.jpg'),
      thumbnail: require('../imgs/Kreis.jpg'),
    },

    {
      original: require('../imgs/kreis2.jpg'),
      thumbnail: require('../imgs/kreis2.jpg'),
    },
    {
      original: require('../imgs/hug.jpg'),
      thumbnail: require('../imgs/hug.jpg'),
    },
    {
      original: require('../imgs/schule.jpg'),
      thumbnail: require('../imgs/schule.jpg'),
    },
    {
      original: require('../imgs/restaurant.jpg'),
      thumbnail: require('../imgs/restaurant.jpg'),
    },
    {
      original: require('../imgs/steine.jpg'),
      thumbnail: require('../imgs/steine.jpg'),
    },
    {
      original: require('../imgs/bett.JPG'),
      thumbnail: require('../imgs/bett.JPG'),
    },

    {
      original: require('../imgs/Feuer2.jpg'),
      thumbnail: require('../imgs/Feuer2.jpg'),
    },
  ];

  return (
    <div style={{ marginBottom: "30px" }}>
      <ImageGallery items={images} showPlayButton={false} />
    </div>
  );
};

export default Gallery;
