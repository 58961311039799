import Logo from "../imgs/logo.png"
import "../css/article.scss"
import {ReadMoreButton} from "../UI/Buttons";

export const ArticleBiodanza = () => {
    return (
        <div className="d-flex center">

            <div  className="article-container d-flex center">
                <div className="article-header d-flex center">
                    <img src={Logo} alt="Logo" style={{height: "200px"}}/>
                </div>


                <div className="title-with-image-container">
                    <h2 className="title">
                        <span className="image-text">Biodanza </span>
                    </h2>
                </div>

                <div className="article-content center">
                    <p>
                        Biodanza ist ein von Rolando Toro Araneda kreiertes System auf der Basis von anthropologischen Studien über den Tanz und die ursprünglichen Riten zum Feiern des Lebens.

                        Biodanza ist ein System zur affektiven und organischen Integration und zur Entwicklung des menschlichen Potenzials durch Tanz, Musik und Begegnungssituationen in der Gruppe. Es ist eine Wissenschaft und gleichzeitig eine Poesie der Begegnung, eine subtile Art der Integration in und mit dem Universum.
                    </p>
                    <ReadMoreButton link={"/biodanza"}/>

                </div>

            </div>
        </div>
    );
};


