import Logo from "../imgs/Logo_Hari_eigen.jpg"
import { BackButton } from "../UI/Buttons";
import BilderGallery from "./BilderGallery";

export const Biodanzaschule = () => {
  return (
    <>
      <BackButton path={-1} />

      <div className=" article-container-new">
        <div className="article center">
          {/*<img src={Logo} alt="Logo" style={{height: "200px"}}/>*/}
          {/*<h1>Biodanza Schule Freiburg</h1>*/}
          {/*<h1>System Rolando Toro</h1>*/}

          <h2>
            Biodanza Schule Freiburg <br /> System Rolando Toro
          </h2>

          {/*<div className="title-with-image-container">*/}
          {/*    <h1 className="title">*/}
          {/*        <span className="image-text">Biodanza Schule Freiburg <br/> System Rolando Toro</span>*/}
          {/*    </h1>*/}
          {/*</div>*/}


          <p> Die Biodanza Schule Freiburg lebt eine Willkommenskultur und ist Mitglied der Internationalen
            Biodanza Federation.
            <br /> <br />
            Wir arbeiten nach den internationalen Ausbildungsrichtlinien der IBFed und machen uns zum Ziel,
            Schüler:innen individuel in ihren Entwicklungsprozessen bis hin zur Ausübung des Berufes als
            Biodanzalehrer:in zu begleiten. Mit der Ausbildung erwirbst Du eine berufliche, international
            anerkannte Qualifikation. Diese Ausbildung dauert etwa dreieinhalb Jahre.
            <br /> <br />
            Wir kreieren eine geschützte Umgebung, in der wertschätzende, persönliche Erfahrungen in der
            Gruppe gemacht werden dürfen. Du erhälst Werkzeuge und Wissen, die Du in deinen Alltag und
            dein Berufsleben integrieren kannst.
            <br /> <br />
            Unser wunderbares Seminarhaus liegt inmitten der Weinberge und bietet Platz für 41 Menschen.
            Wir leben eine Kultur der sozialen Gemeinschaft, des Miteinanders und erproben neue
            Lernmodelle, die auf dem Erleben und der Wissensvermittlung gründen. Neben den 3 Bett
            Zimmern haben wir Gemeinschaftsbäder und Toiletten, eine Gemeinschaftsküche und
            einen Speisesaal , sowie einen 150 qm großen Raum zum Tanzen. Die großzügige
            Außenanlage mit Feuerstelle und Pizzaofen in mitten der Weinberge läd uns in die Natur
            ein.
            <br /> <br />
            <b> Ort und Räumlichkeiten:<br />
              Seminarhaus am Kaiserstuhl,<br /> 79361 Sasbach Leiselheim </b>
          </p>  </div>
      </div>

      {/* <BilderGallery /> */}
    </>
  );
};


