import "../css/article.scss"
import {BackButton} from "../UI/Buttons";

export const Events = () => {
    return (
        <>
            <BackButton path={-1} />


            <div className="article-container-new">
                <div className="article center">


                    <h2>
                        Events
                    </h2>
                </div></div>
        </>)}
