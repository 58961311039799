import "../css/article.scss"
import {BackButton} from "../UI/Buttons";

export const WasIstBiodanza = () => {
    return (
        <>
            <BackButton path={-1} />
        <div className="article-container-new">
            <div className="article center">


                <h2>
                    Biodanza <br/>
                    System Rolando Toro
                </h2>

<p>
                Biodanza ist ein von Rolando Toro Araneda kreiertes System auf der Basis von anthropologischen Studien über den Tanz und die ursprünglichen Riten zum Feiern des Lebens. <br/><br/>

                Biodanza ist ein System zur affektiven und organischen Integration und zur Entwicklung des menschlichen Potenzials durch Tanz, Musik und Begegnungssituationen in der Gruppe. Es ist eine Wissenschaft und gleichzeitig  eine Poesie der Begegnung, eine subtile Art der Integration in und mit dem Universum.<br/><br/>

                Es handelt sich um ein System der Prophylaxe, der existenziellen Rehabilitation und der psycho-physischen Integration (Zurückdrängen und Auflösen von Dissoziationen). Es basiert auf Bewegungen und Musikstücken, die um das theoretische Modell von Biodanza organisiert sind. Das theoretische Modell sorgt für Kohärenz (theoretischer und inhaltlicher Zusammenhang) und Einheit im System Biodanza.<br/><br/>

                Biodanza aktiviert den Ausdruck der Identität und fördert die Gesundheit, indem es die Auswirkungen von Desorganisations- und Stressfaktoren mindert. Es verbessert die affektive Kommunikation und fördert die Kreativität, nicht nur die künstlerische, sondern auch die existentielle.<br/><br/>

                Die Biodanza Bewegung wird von der International Biodanza Federation (IBFed) geleitet. Die IBFed stellt eine Institution dar, welche die Biodanza Lehrer:innen weltweit vereint.<br/><br/>

                Er war Inhaber des Lehrstuhles für Psychologie an der Katholischen Universität von Chile.<br/><br/>

                Als Privat-Dozent beim Lehrzentrum der Anthropologischen Medizin der Medizinische Fakultät der Universität von Santiago de Chile forschte er über Bewusstseinserweiterung in den Gebieten der Anthropologie und der Psychologie. Kurz vor seinem Tod wurde er als emeritierender Professor der Offenen Interamerikanischen Universität von Buenos Aires ernannt.<br/><br/>

                2019 wurde ihm neben Nelson Mandela, Rosa Parks, Albert Schweitzer und Florence Nightingale und vielen mehr in der Allee der Menschlichkeit in der Toskana eine Eiche mit Gedenktafel für sein Werk gewidmet.<br/><br/>

                2021 wurde er in Spanien für den Friedensnobelpreis nominiert.
                Mittlerweile tanzen Gruppen auf allen Kontinenten.</p>


            </div></div>
        </>
)}